import type { User } from "@/server/db/schema";
import { UserRole } from "~/lib/models/user.model";
import { navigateToDashboard } from "~/lib/utils";
import * as Sentry from "@sentry/browser";

interface AuthPluginReturn {
  user: Readonly<Ref<User | null>>;
}

export default defineNuxtPlugin(async () => {
  const user = useState<User | null>("user", () => null);

  // Function to fetch and update user state
  const updateUserState = async () => {
    const { data } = await useFetch<{ user: User }>("/api/auth/session");
    user.value = data.value?.user || null;

    // Only set Sentry user if we have a user logged in
    if (user.value) {
      Sentry.setUser({
        id: user.value.id,
        email: user.value.email,
      });
    } else {
      // Clear Sentry user context when no user is logged in
      Sentry.setUser(null);
    }
  };

  // Initial fetch
  await updateUserState();

  // Role requirements for protected routes
  const routeRoles: Record<string, UserRole[]> = {
    "/locationowner": [UserRole.LOCATION_OWNER],
    "/operator": [UserRole.OPERATOR],
    "/adagency": [UserRole.AD_AGENCY],
    "/installer": [UserRole.INSTALLER],
    "/admin": [UserRole.SUPER_ADMIN],
  } as const;

  // Combined auth and authorization middleware
  addRouteMiddleware(
    "auth",
    async (to) => {
      const publicRoutes = ["/login", "/register", "/forgotpassword"];

      // Handle public routes - allow direct access
      if (publicRoutes.includes(to.path)) {
        // Only redirect away from login if user is authenticated
        if (user.value && to.path === "/login") {
          return navigateToDashboard(user.value.role);
        }
        return;
      }

      // Handle unauthenticated users
      if (!user.value) {
        if (!to.path.startsWith("/login")) {
          // Create full URL including query params
          const fullPath = to.fullPath;
          // If the path already has query params, they'll be included in fullPath
          return navigateTo(`/login?redirect=${encodeURIComponent(fullPath)}`);
        }
        return;
      }

      // Handle root route
      if (to.path === "/") {
        return navigateToDashboard(user.value.role);
      }

      // Check role-based access
      const protectedRoute = Object.keys(routeRoles).find((path) =>
        to.path.startsWith(path),
      );

      if (protectedRoute) {
        const requiredRoles = routeRoles[protectedRoute];
        if (!user.value || !Object.values(UserRole).includes(user.value.role)) {
          return navigateTo("/login");
        }

        if (!requiredRoles.includes(user.value.role)) {
          return navigateToDashboard(user.value.role);
        }
      }
    },
    { global: true },
  );

  return {
    provide: {
      user: readonly(user),
    } satisfies AuthPluginReturn,
  };
});

declare module "#app" {
  interface NuxtApp {
    $user: Readonly<Ref<User | null>>;
  }
}
