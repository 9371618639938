import type { User } from "@/server/db/schema";

export enum UserRole {
  UNKNOWN = -1,
  LOCATION_OWNER = 0,
  OPERATOR = 1,
  AD_AGENCY = 2,
  INSTALLER = 3,
  SUPER_ADMIN = 999,
}

export const getUserRole = (user: User) => {
  if (user.role === undefined || user.role === null) {
    console.error("User has no role defined");
    return "Unknown";
  }

  switch (user.role) {
    case UserRole.LOCATION_OWNER:
      return "Location Owner";
    case UserRole.OPERATOR:
      return "Operator";
    case UserRole.AD_AGENCY:
      return "Ad Agency";
    case UserRole.SUPER_ADMIN:
      return "Admin";
    case UserRole.INSTALLER:
      return "Installer";
    case UserRole.UNKNOWN:
      return "Unknown";
    default:
      console.error("Unknown user role", user.role);
      return "Unknown";
  }
};

export const getRole = (role: number) => {
  switch (role) {
    case UserRole.LOCATION_OWNER:
      return "Location Owner";
    case UserRole.OPERATOR:
      return "Operator";
    case UserRole.AD_AGENCY:
      return "Ad Agency";
    case UserRole.SUPER_ADMIN:
      return "Administrator";
    case UserRole.INSTALLER:
      return "Installer";
    case UserRole.UNKNOWN:
      return "Unknown";
    default:
      console.error("Unknown user role", role);
      return "Unknown";
  }
};

export const UserRoleOptions = [
  {
    label: "Administrator",
    value: UserRole.SUPER_ADMIN,
  },
  {
    label: "Location Owner",
    value: UserRole.LOCATION_OWNER,
  },
  {
    label: "Operator",
    value: UserRole.OPERATOR,
  },
  {
    label: "Ad Agency",
    value: UserRole.AD_AGENCY,
  },
  {
    label: "Installer",
    value: UserRole.INSTALLER,
  },
];

export type UserRoleType = UserRole;
