import { default as index3FRFjbvafbMeta } from "/opt/buildhome/repo/pages/adagency/ads/index.vue?macro=true";
import { default as _91id_93uMmR3TDieNMeta } from "/opt/buildhome/repo/pages/adagency/campaigns/[id].vue?macro=true";
import { default as createlGnafYJaSsMeta } from "/opt/buildhome/repo/pages/adagency/campaigns/create.vue?macro=true";
import { default as indexMApblWBP5BMeta } from "/opt/buildhome/repo/pages/adagency/campaigns/index.vue?macro=true";
import { default as indexV7PVDMjJw9Meta } from "/opt/buildhome/repo/pages/adagency/index.vue?macro=true";
import { default as index1FikR1rs3wMeta } from "/opt/buildhome/repo/pages/adagency/locationgroups/index.vue?macro=true";
import { default as _91id_93lapU7Hid6WMeta } from "/opt/buildhome/repo/pages/adagency/locations/[id].vue?macro=true";
import { default as indexxEk1WFUfzFMeta } from "/opt/buildhome/repo/pages/adagency/locations/index.vue?macro=true";
import { default as adagencyHIiaBOL9QXMeta } from "/opt/buildhome/repo/pages/adagency.vue?macro=true";
import { default as _91id_93sidn0ld7wAMeta } from "/opt/buildhome/repo/pages/admin/campaigns/[id].vue?macro=true";
import { default as createKm8pDL1h3cMeta } from "/opt/buildhome/repo/pages/admin/campaigns/create.vue?macro=true";
import { default as _91id_9312z0eGZRlHMeta } from "/opt/buildhome/repo/pages/admin/campaigns/history/[id].vue?macro=true";
import { default as indexFfLAEfSisNMeta } from "/opt/buildhome/repo/pages/admin/campaigns/index.vue?macro=true";
import { default as _91id_93fV02b3cA5EMeta } from "/opt/buildhome/repo/pages/admin/campaigns/modify/[id].vue?macro=true";
import { default as CampaignSettingsEzNQzzkGAIMeta } from "/opt/buildhome/repo/pages/admin/configuration/CampaignSettings.vue?macro=true";
import { default as DefaultCampaignqYroQn4NtGMeta } from "/opt/buildhome/repo/pages/admin/configuration/DefaultCampaign.vue?macro=true";
import { default as indexxpSpz47CULMeta } from "/opt/buildhome/repo/pages/admin/configuration/index.vue?macro=true";
import { default as LocationTypesxlEPnilpPwMeta } from "/opt/buildhome/repo/pages/admin/configuration/LocationTypes.vue?macro=true";
import { default as Playersl0XyCjG0ToMeta } from "/opt/buildhome/repo/pages/admin/configuration/Players.vue?macro=true";
import { default as ProgrammaticProvidersqKECP7F7PHMeta } from "/opt/buildhome/repo/pages/admin/configuration/ProgrammaticProviders.vue?macro=true";
import { default as _91id_939nFtahaIjoMeta } from "/opt/buildhome/repo/pages/admin/devices/[id].vue?macro=true";
import { default as create2q3pVezDPRMeta } from "/opt/buildhome/repo/pages/admin/devices/create.vue?macro=true";
import { default as indexCuYRFp9MJyMeta } from "/opt/buildhome/repo/pages/admin/devices/index.vue?macro=true";
import { default as indexhyMXUmjJA7Meta } from "/opt/buildhome/repo/pages/admin/index.vue?macro=true";
import { default as _91id_935ouxjHPze1Meta } from "/opt/buildhome/repo/pages/admin/installations/[id].vue?macro=true";
import { default as _91id_93EBdfQQIQ8JMeta } from "/opt/buildhome/repo/pages/admin/installations/copy/[id].vue?macro=true";
import { default as createmrqQbq09jmMeta } from "/opt/buildhome/repo/pages/admin/installations/create.vue?macro=true";
import { default as index65blP15mINMeta } from "/opt/buildhome/repo/pages/admin/installations/index.vue?macro=true";
import { default as reportsfdnX1mZlSEMeta } from "/opt/buildhome/repo/pages/admin/installations/reports.vue?macro=true";
import { default as indexKPyRUcX0v9Meta } from "/opt/buildhome/repo/pages/admin/locationgroups/index.vue?macro=true";
import { default as _91id_93M7FORm7vXKMeta } from "/opt/buildhome/repo/pages/admin/locations/[id].vue?macro=true";
import { default as createCHhgSS4WK9Meta } from "/opt/buildhome/repo/pages/admin/locations/create.vue?macro=true";
import { default as indexryoePHhLhUMeta } from "/opt/buildhome/repo/pages/admin/locations/index.vue?macro=true";
import { default as OpeningHours45H9bkFjEGMeta } from "/opt/buildhome/repo/pages/admin/locations/OpeningHours.vue?macro=true";
import { default as index9vf1OCV1S3Meta } from "/opt/buildhome/repo/pages/admin/notifications/index.vue?macro=true";
import { default as index8mjJwSzZYFMeta } from "/opt/buildhome/repo/pages/admin/profile/index.vue?macro=true";
import { default as indexoaq2ni4lAVMeta } from "/opt/buildhome/repo/pages/admin/reports/index.vue?macro=true";
import { default as indexWGBwIiVTtWMeta } from "/opt/buildhome/repo/pages/admin/shipping/index.vue?macro=true";
import { default as _91id_937MxNPRDNWUMeta } from "/opt/buildhome/repo/pages/admin/simcards/[id].vue?macro=true";
import { default as indexhkasty5vaAMeta } from "/opt/buildhome/repo/pages/admin/simcards/index.vue?macro=true";
import { default as ProductSelectorvrtvgUomRDMeta } from "/opt/buildhome/repo/pages/admin/simcards/ProductSelector.vue?macro=true";
import { default as _91id_93qQW7PBCtvCMeta } from "/opt/buildhome/repo/pages/admin/users/[id].vue?macro=true";
import { default as indexXtUngUp57YMeta } from "/opt/buildhome/repo/pages/admin/users/index.vue?macro=true";
import { default as admin02XFd05CPxMeta } from "/opt/buildhome/repo/pages/admin.vue?macro=true";
import { default as changepasswordXnpRbQuJQlMeta } from "/opt/buildhome/repo/pages/changepassword.vue?macro=true";
import { default as forgotpassword02IPaVZWWrMeta } from "/opt/buildhome/repo/pages/forgotpassword.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexZXsxW4mO7RMeta } from "/opt/buildhome/repo/pages/installer/index.vue?macro=true";
import { default as indexqFew3HK3lHMeta } from "/opt/buildhome/repo/pages/installer/profile/index.vue?macro=true";
import { default as _91id_93GjkUoiNppNMeta } from "/opt/buildhome/repo/pages/installer/task/[id].vue?macro=true";
import { default as installern1ozRyAz2mMeta } from "/opt/buildhome/repo/pages/installer.vue?macro=true";
import { default as _91id_93timi4h9hp4Meta } from "/opt/buildhome/repo/pages/locationowner/campaigns/[id].vue?macro=true";
import { default as createLTNXN1B3m2Meta } from "/opt/buildhome/repo/pages/locationowner/campaigns/create.vue?macro=true";
import { default as index9aYsnyDH7EMeta } from "/opt/buildhome/repo/pages/locationowner/campaigns/index.vue?macro=true";
import { default as indexy4Of6iioYOMeta } from "/opt/buildhome/repo/pages/locationowner/index.vue?macro=true";
import { default as indexwjOorwHTpLMeta } from "/opt/buildhome/repo/pages/locationowner/locationgroups/index.vue?macro=true";
import { default as _91id_930hyxbkeoffMeta } from "/opt/buildhome/repo/pages/locationowner/locations/[id].vue?macro=true";
import { default as indexpGSUko33I6Meta } from "/opt/buildhome/repo/pages/locationowner/locations/index.vue?macro=true";
import { default as indexhZeSFVxxOqMeta } from "/opt/buildhome/repo/pages/locationowner/profile/index.vue?macro=true";
import { default as locationownerjtiVyh8TyEMeta } from "/opt/buildhome/repo/pages/locationowner.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _91id_93w5hE5hU7G5Meta } from "/opt/buildhome/repo/pages/operator/campaigns/[id].vue?macro=true";
import { default as createvk03Pua4b4Meta } from "/opt/buildhome/repo/pages/operator/campaigns/create.vue?macro=true";
import { default as indexXHLTLoIA9rMeta } from "/opt/buildhome/repo/pages/operator/campaigns/index.vue?macro=true";
import { default as indexaGT12yHdMyMeta } from "/opt/buildhome/repo/pages/operator/index.vue?macro=true";
import { default as indexaNvteCgAUfMeta } from "/opt/buildhome/repo/pages/operator/locationgroups/index.vue?macro=true";
import { default as _91id_93MCGstgN4eOMeta } from "/opt/buildhome/repo/pages/operator/locations/[id].vue?macro=true";
import { default as indexix7q1D4zDQMeta } from "/opt/buildhome/repo/pages/operator/locations/index.vue?macro=true";
import { default as indexvbez9L2WFnMeta } from "/opt/buildhome/repo/pages/operator/profile/index.vue?macro=true";
import { default as index9sG6i904gIMeta } from "/opt/buildhome/repo/pages/operator/reports/index.vue?macro=true";
import { default as operatorUDg64585YbMeta } from "/opt/buildhome/repo/pages/operator.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as _91id_9337AbSHTDUTMeta } from "/opt/buildhome/repo/pages/resetpassword/[id].vue?macro=true";
import { default as indexExi0T6aS0fMeta } from "/opt/buildhome/repo/pages/resetpassword/index.vue?macro=true";
export default [
  {
    name: adagencyHIiaBOL9QXMeta?.name,
    path: "/adagency",
    component: () => import("/opt/buildhome/repo/pages/adagency.vue"),
    children: [
  {
    name: "adagency-ads",
    path: "ads",
    component: () => import("/opt/buildhome/repo/pages/adagency/ads/index.vue")
  },
  {
    name: "adagency-campaigns-id",
    path: "campaigns/:id()",
    component: () => import("/opt/buildhome/repo/pages/adagency/campaigns/[id].vue")
  },
  {
    name: "adagency-campaigns-create",
    path: "campaigns/create",
    component: () => import("/opt/buildhome/repo/pages/adagency/campaigns/create.vue")
  },
  {
    name: "adagency-campaigns",
    path: "campaigns",
    component: () => import("/opt/buildhome/repo/pages/adagency/campaigns/index.vue")
  },
  {
    name: "adagency",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/adagency/index.vue")
  },
  {
    name: "adagency-locationgroups",
    path: "locationgroups",
    component: () => import("/opt/buildhome/repo/pages/adagency/locationgroups/index.vue")
  },
  {
    name: "adagency-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/adagency/locations/[id].vue")
  },
  {
    name: "adagency-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/adagency/locations/index.vue")
  }
]
  },
  {
    name: admin02XFd05CPxMeta?.name,
    path: "/admin",
    component: () => import("/opt/buildhome/repo/pages/admin.vue"),
    children: [
  {
    name: "admin-campaigns-id",
    path: "campaigns/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/campaigns/[id].vue")
  },
  {
    name: "admin-campaigns-create",
    path: "campaigns/create",
    component: () => import("/opt/buildhome/repo/pages/admin/campaigns/create.vue")
  },
  {
    name: "admin-campaigns-history-id",
    path: "campaigns/history/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/campaigns/history/[id].vue")
  },
  {
    name: "admin-campaigns",
    path: "campaigns",
    component: () => import("/opt/buildhome/repo/pages/admin/campaigns/index.vue")
  },
  {
    name: "admin-campaigns-modify-id",
    path: "campaigns/modify/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/campaigns/modify/[id].vue")
  },
  {
    name: "admin-configuration-CampaignSettings",
    path: "configuration/CampaignSettings",
    component: () => import("/opt/buildhome/repo/pages/admin/configuration/CampaignSettings.vue")
  },
  {
    name: "admin-configuration-DefaultCampaign",
    path: "configuration/DefaultCampaign",
    component: () => import("/opt/buildhome/repo/pages/admin/configuration/DefaultCampaign.vue")
  },
  {
    name: "admin-configuration",
    path: "configuration",
    component: () => import("/opt/buildhome/repo/pages/admin/configuration/index.vue")
  },
  {
    name: "admin-configuration-LocationTypes",
    path: "configuration/LocationTypes",
    component: () => import("/opt/buildhome/repo/pages/admin/configuration/LocationTypes.vue")
  },
  {
    name: "admin-configuration-Players",
    path: "configuration/Players",
    component: () => import("/opt/buildhome/repo/pages/admin/configuration/Players.vue")
  },
  {
    name: "admin-configuration-ProgrammaticProviders",
    path: "configuration/ProgrammaticProviders",
    component: () => import("/opt/buildhome/repo/pages/admin/configuration/ProgrammaticProviders.vue")
  },
  {
    name: "admin-devices-id",
    path: "devices/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/devices/[id].vue")
  },
  {
    name: "admin-devices-create",
    path: "devices/create",
    component: () => import("/opt/buildhome/repo/pages/admin/devices/create.vue")
  },
  {
    name: "admin-devices",
    path: "devices",
    component: () => import("/opt/buildhome/repo/pages/admin/devices/index.vue")
  },
  {
    name: "admin",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/admin/index.vue")
  },
  {
    name: "admin-installations-id",
    path: "installations/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/[id].vue")
  },
  {
    name: "admin-installations-copy-id",
    path: "installations/copy/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/copy/[id].vue")
  },
  {
    name: "admin-installations-create",
    path: "installations/create",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/create.vue")
  },
  {
    name: "admin-installations",
    path: "installations",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/index.vue")
  },
  {
    name: "admin-installations-reports",
    path: "installations/reports",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/reports.vue")
  },
  {
    name: "admin-locationgroups",
    path: "locationgroups",
    component: () => import("/opt/buildhome/repo/pages/admin/locationgroups/index.vue")
  },
  {
    name: "admin-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/locations/[id].vue")
  },
  {
    name: "admin-locations-create",
    path: "locations/create",
    component: () => import("/opt/buildhome/repo/pages/admin/locations/create.vue")
  },
  {
    name: "admin-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/admin/locations/index.vue")
  },
  {
    name: "admin-locations-OpeningHours",
    path: "locations/OpeningHours",
    component: () => import("/opt/buildhome/repo/pages/admin/locations/OpeningHours.vue")
  },
  {
    name: "admin-notifications",
    path: "notifications",
    component: () => import("/opt/buildhome/repo/pages/admin/notifications/index.vue")
  },
  {
    name: "admin-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/admin/profile/index.vue")
  },
  {
    name: "admin-reports",
    path: "reports",
    component: () => import("/opt/buildhome/repo/pages/admin/reports/index.vue")
  },
  {
    name: "admin-shipping",
    path: "shipping",
    component: () => import("/opt/buildhome/repo/pages/admin/shipping/index.vue")
  },
  {
    name: "admin-simcards-id",
    path: "simcards/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/simcards/[id].vue")
  },
  {
    name: "admin-simcards",
    path: "simcards",
    component: () => import("/opt/buildhome/repo/pages/admin/simcards/index.vue")
  },
  {
    name: "admin-simcards-ProductSelector",
    path: "simcards/ProductSelector",
    component: () => import("/opt/buildhome/repo/pages/admin/simcards/ProductSelector.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/opt/buildhome/repo/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "changepassword",
    path: "/changepassword",
    component: () => import("/opt/buildhome/repo/pages/changepassword.vue")
  },
  {
    name: "forgotpassword",
    path: "/forgotpassword",
    component: () => import("/opt/buildhome/repo/pages/forgotpassword.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: installern1ozRyAz2mMeta?.name,
    path: "/installer",
    component: () => import("/opt/buildhome/repo/pages/installer.vue"),
    children: [
  {
    name: "installer",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/installer/index.vue")
  },
  {
    name: "installer-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/installer/profile/index.vue")
  },
  {
    name: "installer-task-id",
    path: "task/:id()",
    component: () => import("/opt/buildhome/repo/pages/installer/task/[id].vue")
  }
]
  },
  {
    name: locationownerjtiVyh8TyEMeta?.name,
    path: "/locationowner",
    component: () => import("/opt/buildhome/repo/pages/locationowner.vue"),
    children: [
  {
    name: "locationowner-campaigns-id",
    path: "campaigns/:id()",
    component: () => import("/opt/buildhome/repo/pages/locationowner/campaigns/[id].vue")
  },
  {
    name: "locationowner-campaigns-create",
    path: "campaigns/create",
    component: () => import("/opt/buildhome/repo/pages/locationowner/campaigns/create.vue")
  },
  {
    name: "locationowner-campaigns",
    path: "campaigns",
    component: () => import("/opt/buildhome/repo/pages/locationowner/campaigns/index.vue")
  },
  {
    name: "locationowner",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/locationowner/index.vue")
  },
  {
    name: "locationowner-locationgroups",
    path: "locationgroups",
    component: () => import("/opt/buildhome/repo/pages/locationowner/locationgroups/index.vue")
  },
  {
    name: "locationowner-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/locationowner/locations/[id].vue")
  },
  {
    name: "locationowner-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/locationowner/locations/index.vue")
  },
  {
    name: "locationowner-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/locationowner/profile/index.vue")
  }
]
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: operatorUDg64585YbMeta?.name,
    path: "/operator",
    component: () => import("/opt/buildhome/repo/pages/operator.vue"),
    children: [
  {
    name: "operator-campaigns-id",
    path: "campaigns/:id()",
    component: () => import("/opt/buildhome/repo/pages/operator/campaigns/[id].vue")
  },
  {
    name: "operator-campaigns-create",
    path: "campaigns/create",
    component: () => import("/opt/buildhome/repo/pages/operator/campaigns/create.vue")
  },
  {
    name: "operator-campaigns",
    path: "campaigns",
    component: () => import("/opt/buildhome/repo/pages/operator/campaigns/index.vue")
  },
  {
    name: "operator",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/operator/index.vue")
  },
  {
    name: "operator-locationgroups",
    path: "locationgroups",
    component: () => import("/opt/buildhome/repo/pages/operator/locationgroups/index.vue")
  },
  {
    name: "operator-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/operator/locations/[id].vue")
  },
  {
    name: "operator-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/operator/locations/index.vue")
  },
  {
    name: "operator-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/operator/profile/index.vue")
  },
  {
    name: "operator-reports",
    path: "reports",
    component: () => import("/opt/buildhome/repo/pages/operator/reports/index.vue")
  }
]
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/opt/buildhome/repo/pages/register.vue")
  },
  {
    name: "resetpassword-id",
    path: "/resetpassword/:id()",
    component: () => import("/opt/buildhome/repo/pages/resetpassword/[id].vue")
  },
  {
    name: "resetpassword",
    path: "/resetpassword",
    component: () => import("/opt/buildhome/repo/pages/resetpassword/index.vue")
  }
]