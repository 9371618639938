import revive_payload_client_YPlsEvRWNU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TTVOCZT3oY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YZ1SdKIxoS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6pemthnvBu from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NBEIlPIRtf from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_N899V416kj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_t50UhV4RJ1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5aRl2zcj3b from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11.7.0_drizzle-orm@0._e45mctggcqx6vsdw5vaaxrwlqy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nh2HihStmo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-echarts@0.2.4_echarts@5.5.1_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_zvO511wSxm from "/opt/buildhome/repo/node_modules/.pnpm/@primevue+nuxt-module@4.2.4_@babel+parser@7.26.3_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import _01_sentryClientPlugin_Riwl5WCia6 from "/opt/buildhome/repo/plugins/01.sentryClientPlugin.ts";
import _02_auth_kllCetJ9Sb from "/opt/buildhome/repo/plugins/02.auth.ts";
export default [
  revive_payload_client_YPlsEvRWNU,
  unhead_TTVOCZT3oY,
  router_YZ1SdKIxoS,
  payload_client_6pemthnvBu,
  navigation_repaint_client_NBEIlPIRtf,
  check_outdated_build_client_N899V416kj,
  chunk_reload_client_t50UhV4RJ1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5aRl2zcj3b,
  plugin_nh2HihStmo,
  primevue_plugin_egKpok8Auk,
  plugin_client_zvO511wSxm,
  _01_sentryClientPlugin_Riwl5WCia6,
  _02_auth_kllCetJ9Sb
]